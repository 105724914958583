import m from 'mithril';

export const NavigationUtil = {
	navigateTo(path, opts) {
		m.route.set(path.path, opts,  { title: (path.title)? path.title : 'OpenCTF' });
	}
};

export const DashboardHomePath = {
	title: 'WIE MTU SYP CTF | Dashboard Home',
	path: '/',
};

export const LoginPath = {
	title: 'WIE MTU SYP CTF | Login',
	path: '/login',
};

export const CreateAccountPath = {
	title: 'WIE MTU SYP CTF | Create Account',
	path: '/create-account',
};

export const ForgotPassword = {
	title: 'WIE MTU SYP CTF | Create Account',
	path: '/login?forgot-password',
};

export function TeamProfilePath(id) {
	return {
		title: 'WIE MTU SYP CTF | Team Profile',
		path: `/team/${id}`,
	};
}

export const CreateTeamPath = {
	title: 'WIE MTU SYP CTF | Create Team',
	path: '/create-team',
};

export const EditTeamPath = {
	title: 'WIE MTU SYP CTF | Edit Team',
	path: '/team/:id/edit',
};

export const RecoveryPath = {
	title: 'WIE MTU SYP CTF | Password Recovery',
	path: '/login/forgot-password',
};

export const DashboardTeamlessPath = {
	title: 'WIE MTU SYP CTF | Dashboard',
	path: '/dashboard-teamless',
};

export function UserProfilePath(id) {
	return {
		title: 'WIE MTU SYP CTF | User Profile',
		path: `/user/${id}`,
	};
}

export const TeamDirectoryPath = {
	title: 'WIE MTU SYP CTF | Browse Teams',
	path: '/browse-teams'
};

export const UserProfileEdit = {
	title: 'WIE MTU SYP CTF | Edit User Profile',
	path: '/user/:id/edit'
};

export const ModuleListPath = {
	title: 'WIE MTU SYP CTF | Modules',
	path: '/modules'
};

export const ScoreboardPath = {
	title: 'WIE MTU SYP CTF | Scoreboard',
	path: '/scoreboard'
};

export const CreateModulePath = {
	title: 'Create Module',
	path: '/module/new'
};

/**
 * Generate a route to navigate to a given module
 * @param {number} module_id The id of the module to navigate to
 * @param {string|number} section_id The id of the section to navigate to in the given module, or 'new' to create a new section.
 * @param {string} mode View mode {edit,view}.
 * @return {Object} Routing parameters to pass to route function
 */
export function ModuleViewPath(module_id, section_id, mode) {
	return {
		title: 'WIE MTU SYP CTF | Module Viewer',
		path: `/module/${module_id}/${section_id}/${mode}`
	};
}
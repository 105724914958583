export default {
	logDebug(message) {
		if (window.$config.console_debug === true) {
			console.log(`[OpenCTF][DEBUG] ${message}`);
		}
	},

	logMessage(message) {
		console.log(`[OpenCTF][INFO] ${message}`);
	},

	logError(error) {
		console.error(`[OpenCTF][ERROR] Application encountered an unexpected error ${error.message}`);
	},

	generateAPIError(endpoint) {
		return new Error(`Error processing API response from endpoint ${endpoint}.`);
	}
};
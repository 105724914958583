export function stringOrDefault(value, orDefault = '') {
	if (value === undefined || value === null) {
		return orDefault;
	}
	return value;
}

export function dateOrDefault(value, orDefault = '') {
	if (value instanceof Date) {
		return `${value.getFullYear()}-${`00${value.getMonth() +1}`.substr(-2)}-${`00${value.getDate()}`.substr(-2)}`;
	}
	return orDefault;
}

export function valueExists(value) {
	return !(value === undefined || value === null);
}

export function valueMeaningfullyExists(value) {
	return !(value === undefined || value === null || value.length === 0);
}

export function isValidPassword(value) {
	if (valueExists(value)) {
		return value.length > 8;
	}
	return false;
}

export function isValidEmail(value) {
	if (valueExists(value)) {
		return value.includes('@');
	}
	return false;
}

export function isValidURL(value) {

	let regexp =  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
	if (regexp.test(value)) {
		return true;
	} else {
		return false;
	}
}
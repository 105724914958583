import m from 'mithril';
import { init as initApm, apm } from '@elastic/apm-rum';

import DevelopmentConfig from '@/config/config.development.json';
import LocalConfig from '@/config/config.local.json';
import ProductionConfig from '@/config/config.production.json';

import LoadingCover from '@/components/LoadingCover.js';

import Authentication from '@/services/Authentication.js';

import LoadingCoverState from '@/state/LoadingCover.js';
import { NavigationUtil, LoginPath } from '@/routeOptions.js';
import { getCookieValueByKey } from '@/util/cookieUtils.js';
import LogAggregator from '@/logger/Aggregator.js';

import '@/css/main.css';
import '@/css/utilities.css';
/**
 * Load in 3rd party services
 * Authentication / authorization
 * Initialize any default state that SPA may need before loading.
 *
 * The index.html file can contain an initial loading page, or import Mithril and mount one here.
 */

// Make application config available
if (window.location.host == 'ctf.needlestack.tech') {
	window.$config = ProductionConfig;
} else if (window.location.host == 'ctf-dev.needlestack.tech') {
	window.$config = DevelopmentConfig;
} else {
	window.$config = LocalConfig;
}

// Initialize global loading cover state for layouts
window.$LoadingCoverState = new LoadingCoverState();

// Show the loading cover while the application loads
window.$LoadingCoverState.showLoadingCover();
m.mount(document.body, { view() {
	return m('', { style: { width: '100vw', height: '100vh' } }, m(LoadingCover));
}});

initApm(window.$config.apm);
const sid = getCookieValueByKey(window.$config.phpsid);
if (sid) {
	apm.setCustomContext({ phpsid: sid });
}

window.onerror = function(errMsg, url, line, column, error) {
	apm.captureError(error);
};

/**
 * Application startup sequence,
 * 1. Async fetch router and determine auth state
 * 2. Redirect out of secure areas if not logged in
 * 3. Implicit router handoff.
 */
window.$Authentication = new Authentication();
window.$Authentication.resumeSession()
	.then(() => {
		import(/* webpackChunkName: "Router" */ '@/router.js')
			.then(() => {
				window.$LoadingCoverState.hideLoadingCover();
				if (window.$Authentication.isAuthenticated) {
					apm.setUserContext({ id: window.$Authentication.getAccountId() });
				} else {
					LogAggregator.logMessage('Existing session not found. Initializing application as anonymous user.');
					NavigationUtil.navigateTo(LoginPath);
				}
			});
	}).catch((error) => {
		console.log(error);
		LogAggregator.logError(new Error('Unable to communicate with API server. Application loading stalled.'));
	});

//Middleware made for handling http and json response codes and executing standard actions.
import m from 'mithril';

import {NavigationUtil, LoginPath } from '@/routeOptions.js';
import LogAggregator from '@/logger/Aggregator.js';

export default function request(url, options) {
	return m.request(url, options)
		.then((resp) => {
			//Look for API status
			return resp;

		}).catch((err) => {
			//Look for HTTP Errors
			switch(err.code){
			case 401:
				window.$Authentication.logout()
					.then(() => {
						NavigationUtil.navigateTo(LoginPath);
					})
					.catch((error) => {
						LogAggregator.logError(error);
					})
					.finally(() => {
						window.$LoadingCoverState.hideLoadingCover();
					});
				break;
			default:
				console.log('No Specific Conditions met');
				//redirect
			}
			throw err;
		});
}
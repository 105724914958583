import m from 'mithril';
import { apm } from  '@elastic/apm-rum';

import { valueMeaningfullyExists } from '@/util/valueOrDefault.js';
import LogAggregator from '@/logger/Aggregator.js';
import Request from '@/middleware/request.js';

export default class Authentication {
	getAccountId() { return this.account_id; }
	isAuthenticated() { return this.is_logged_in; }
	getFirstName() { return this.f_name; }
	getLastName() { return this.l_name; }
	getTeamId(){ return this.team_id; }
	getAccountType() { return this.account_type; }

	constructor() {
		this.account_id = undefined;
		this.is_logged_in = false;
		this.f_name = undefined;
		this.l_name = undefined;
		this.team_id = undefined;
		this.account_type = undefined;
	}

	/**
	 * This endpoint supports fileuploading and works with multipart/formdata rather than JSON
	 * @param formData Expects a FormData Object with the necessary fields
	 */
	async createLocalAccount(formData) {
		const transaction = apm.startTransaction('Authentication.createLocalAccount', 'custom', { managed: true });
		transaction.block(true);
		const span = transaction.startSpan('fetch');

		return Request(`${window.$config.api_endpoint}/authentication/local/create`, {
			method: 'POST',
			withCredentials: true,
			body: formData,
		}).then((resp) => {
			this.is_logged_in = resp.data.is_logged_in;
			this.account_id = resp.data.account_id;
			this.f_name = resp.data.f_name;
			this.l_name = resp.data.l_name;
			if (valueMeaningfullyExists(resp.data.team_id)) {
				this.team_id = resp.data.team_id;
			}
			this.account_type = resp.data.account_type;
			return true;
		}).catch((error) => {
			transaction.addLabels({ http_code: error.code });
			transaction.addLabels({ http_response: JSON.stringify(error) });
			transaction.addLabels({ error_message: error.message });
			throw new Error(error.response.error);
		}).finally(() => {
			span.end();
			transaction.end();
		});
	}

	async RecoverPassword(email){
		await Request(`${window.$config.api_endpoint}/authentication/local/recovery?email=${email}`, {
			method: 'POST',
			withCredentials: false,

		}).then(() => {
			return;
		}).catch((error) => {
			throw new Error(error.response.error);
		});
	}

	async resumeSession() {
		const transaction = apm.startTransaction('Authentication.resumeSession', 'custom', { managed: true });
		transaction.block(true);
		const span = transaction.startSpan('fetch');

		return Request(`${window.$config.api_endpoint}/authentication/ping`, {
			method: 'GET',
			withCredentials: true,
		}).then((resp) => {
			this.is_logged_in = resp.data.is_logged_in;
			this.account_id = resp.data.account_id;
			this.f_name = resp.data.f_name;
			this.l_name = resp.data.l_name;
			this.team_id = resp.data.team_id;
			this.account_type = resp.data.account_type;
			LogAggregator.logDebug(`Authentication ping reported login status ${this.is_logged_in}`);

		}).catch((error) => {
			this.logout();
			LogAggregator.logError(error);
			transaction.addLabels({ http_code: error.code });
			transaction.addLabels({ http_response: JSON.stringify(error) });
			transaction.addLabels({ error_message: error.message });
			apm.captureError(LogAggregator.generateAPIError('/authentication/ping'));
			throw new Error(error.response.error);

		}).finally(() => {
			span.end();
			transaction.end();
		});
	}

	async authenticate(email, password) {
		const transaction = apm.startTransaction('Autnetication.authenticate', 'custom', { managed: true });
		transaction.block(true);
		const span = transaction.startSpan('fetch');

		await Request(`${window.$config.api_endpoint}/authentication/local/authenticate`, {
			method: 'POST',
			withCredentials: true,
			body: {
				email,
				password,
			},
		}).then((resp) => {
			this.is_logged_in = resp.data.is_logged_in;
			this.account_id = resp.data.account_id;
			this.f_name = resp.data.f_name;
			this.l_name = resp.data.l_name;
			if (valueMeaningfullyExists(resp.data.team_id)) {
				this.team_id = resp.data.team_id;
			}
			this.account_type = resp.data.account_type;
			LogAggregator.logDebug(`Authentication request reported login status ${this.is_logged_in}`);
			return true;
		}).catch((error) => {
			LogAggregator.logError(error);
			transaction.addLabels({ http_code: error.code });
			transaction.addLabels({ http_response: JSON.stringify(error) });
			transaction.addLabels({ error_message: error.message });
			apm.captureError(LogAggregator.generateAPIError('/authentication/ping'));
			throw new Error(error.response.error);

		}).finally(() => {
			span.end();
			transaction.end();
		});
	}


	async logout() {
		const transaction = apm.startTransaction('Authentication.logout', 'custom', { managed: true });
		transaction.block(true);
		const span = transaction.startSpan('fetch');

		return Request(`${window.$config.api_endpoint}/authentication/logout`, {
			method: 'GET',
			withCredentials: true,
		}).then(() => {
			this.is_logged_in = false;
			this.account_id = undefined;
			this.f_name = undefined;
			this.l_name = undefined;
			this.team_id = undefined;
			this.account_type = undefined;
			return true;
		}).catch((error) => {
			LogAggregator.logError(error);
			transaction.addLabels({ http_code: error.code });
			transaction.addLabels({ http_response: JSON.stringify(error) });
			transaction.addLabels({ error_message: error.message });
			apm.captureError(LogAggregator.generateAPIError('/authentication/ping'));
			throw new Error(error.response.error);
		}).finally(() => {
			span.end();
			transaction.end();
		});
	}
}
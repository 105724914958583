import m from 'mithril';

import LoadingIcon from '@/assets/images/icons/loading.svg';

import '@/css/components/LoadingCover.css';

export default class LoadingCover {
	view() {
		return <div class='inset-0 fixed bg-black/50 backdrop-blur-md flex justify-center align-center'>
			<div class="loading-spinner text-accent-500 m-auto w-14 h-14">
				<LoadingIcon />
			</div>
		</div>;
	}
}
import m from 'mithril';

export default class LoadingCoverState {

	constructor() {
		this.loadingRequests = 0;
	}

	isCoverShowing() { return this.loadingRequests > 0; }
	showLoadingCover() { this.loadingRequests++; }
	hideLoadingCover() { this.loadingRequests = (this.loadingRequests <= 0)? 0 : this.loadingRequests - 1; }
	resetCover() { this.loadingRequests = 0; }
}